// Mouse movement effect for cards
document.querySelectorAll('.values-card').forEach(card => {
  card.addEventListener('mousemove', (e) => {
      const rect = card.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / card.clientWidth) * 100;
      const y = ((e.clientY - rect.top) / card.clientHeight) * 100;

      card.style.setProperty('--mouse-x', `${x}%`);
      card.style.setProperty('--mouse-y', `${y}%`);
  });
});

// Intersection Observer for scroll animations
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
      if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
          entry.target.style.transform = 'translateY(0)';
      }
  });
}, {
  threshold: 0.1,
  rootMargin: '0px'
});

// Apply to all cards
document.querySelectorAll('.values-card, .team-card').forEach(card => {
  card.style.opacity = '0';
  card.style.transform = 'translateY(20px)';
  card.style.transition = 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)';
  observer.observe(card);
});

// Smooth reveal for social links
document.querySelectorAll('.team-card').forEach(card => {
  card.addEventListener('mouseenter', () => {
      const socialLinks = card.querySelector('.social-links');
      socialLinks.style.opacity = '1';
      socialLinks.style.transform = 'translateY(0)';
  });

  card.addEventListener('mouseleave', () => {
      const socialLinks = card.querySelector('.social-links');
      socialLinks.style.opacity = '0';
      socialLinks.style.transform = 'translateY(10px)';
  });
});

// Particle effect for team section
class Particle {
  constructor(x, y) {
      this.x = x;
      this.y = y;
      this.size = Math.random() * 2 + 1;
      this.speedX = Math.random() * 2 - 1;
      this.speedY = Math.random() * 2 - 1;
      this.opacity = Math.random() * 0.5;
  }

  update() {
      this.x += this.speedX;
      this.y += this.speedY;

      if (this.opacity >= 0.01) this.opacity -= 0.01;
  }

  draw(ctx) {
      ctx.fillStyle = `rgba(99, 102, 241, ${this.opacity})`;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fill();
  }
}

// Initialize particle canvas
const canvas = document.createElement('canvas');
canvas.style.position = 'absolute';
canvas.style.top = '0';
canvas.style.left = '0';
canvas.style.width = '100%';
canvas.style.height = '100%';
canvas.style.pointerEvents = 'none';
document.querySelector('.team-values').prepend(canvas);

const ctx = canvas.getContext('2d');
let particles = [];

function resizeCanvas() {
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;
}

window.addEventListener('resize', resizeCanvas);
resizeCanvas();

// Animation loop for particles
function animate() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  particles.forEach((particle, index) => {
      particle.update();
      particle.draw(ctx);

      if (particle.opacity <= 0.01) {
          particles.splice(index, 1);
      }
  });

  // Add new particles randomly
  if (Math.random() < 0.1 && particles.length < 50) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      particles.push(new Particle(x, y));
  }

  requestAnimationFrame(animate);
}

animate();

// Add particles on mouse move
document.querySelector('.team-values').addEventListener('mousemove', (e) => {
  const rect = canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  if (Math.random() < 0.2) {
      particles.push(new Particle(x, y));
  }
});

// Mouse movement effect for cards
document.querySelectorAll('.advantage-card').forEach(card => {
  card.addEventListener('mousemove', (e) => {
      const rect = card.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / card.clientWidth) * 100;
      const y = ((e.clientY - rect.top) / card.clientHeight) * 100;

      card.style.setProperty('--mouse-x', `${x}%`);
      card.style.setProperty('--mouse-y', `${y}%`);
  });
});

// Scroll reveal animation
const observerAdvantage = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
      if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
          entry.target.style.transform = 'translateY(0)';
      }
  });
}, {
  threshold: 0.1,
  rootMargin: '0px'
});

document.querySelectorAll('.advantage-card').forEach(card => {
  card.style.opacity = '0';
  card.style.transform = 'translateY(20px)';
  card.style.transition = 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)';
  observerAdvantage.observe(card);
});

document.querySelectorAll('.faq-item').forEach(item => {
  const question = item.querySelector('.faq-question');
  const answer = item.querySelector('.faq-answer');
  const answerText = item.querySelector('.faq-answer-text');

  question.addEventListener('click', () => {
      const isActive = item.classList.contains('active');

      // Close all other items
      document.querySelectorAll('.faq-item').forEach(otherItem => {
          if (otherItem !== item) {
              otherItem.classList.remove('active');
              otherItem.querySelector('.faq-answer').style.height = '0';
              otherItem.querySelector('.faq-answer').style.paddingTop = '0';
          }
      });

      // Toggle current item
      if (!isActive) {
          item.classList.add('active');
          answer.style.height = answerText.offsetHeight + 'px';
          answer.style.paddingTop = '0';
      } else {
          item.classList.remove('active');
          answer.style.height = '0';
          answer.style.paddingTop = '0';
      }
  });
});

// Create floating particles
const particlesContainer = document.querySelector('.cta-particles');

function createParticle() {
  const particle = document.createElement('div');
  particle.className = 'cta-particle';

  // Random starting position
  const startPos = Math.random() * 100;
  particle.style.left = `${startPos}%`;

  // Random horizontal movement
  const tx = (Math.random() - 0.5) * 200;
  particle.style.setProperty('--tx', `${tx}px`);

  // Random size
  const size = Math.random() * 4 + 2;
  particle.style.width = `${size}px`;
  particle.style.height = `${size}px`;

  // Random duration
  const duration = Math.random() * 5 + 5;
  particle.style.animation = `float-up ${duration}s infinite`;

  particlesContainer.appendChild(particle);

  // Remove particle after animation
  setTimeout(() => {
      particle.remove();
  }, duration * 1000);
}

// Create particles periodically
setInterval(createParticle, 300);

// Add hover effect to buttons
document.querySelectorAll('.cta-btn').forEach(btn => {
  btn.addEventListener('mouseenter', () => {
      btn.style.transform = 'translateY(-2px)';
  });

  btn.addEventListener('mouseleave', () => {
      btn.style.transform = 'translateY(0)';
  });
});

const messages = [
  {
      type: 'bot',
      text: 'Здравствуйте! Я AI-ассистент EXPERT-AI. Готов помочь автоматизировать ваш бизнес.',
      delay: 1000
  },
  {
      type: 'user',
      text: 'Расскажите о ваших услугах',
      delay: 2000
  },
  {
      type: 'bot',
      text: '✨ Мы предлагаем:\n\n• Бесплатную разработку чат-ботов\n• Техническую поддержку 24/7\n• Регулярные обновления',
      delay: 2000
  },
  {
      type: 'user',
      text: 'Как начать работу с вами?',
      delay: 2000
  },
  {
      type: 'bot',
      text: '🚀 Всего 3 простых шага:\n\n1. Анализ ваших задач\n2. Разработка решения\n3. Запуск и поддержка\n\nВся разработка бесплатна!',
      delay: 2000
  }
];

const chatMessages = document.querySelector('.chat-messages');

function addMessage(message) {
  const messageDiv = document.createElement('div');
  messageDiv.className = `message message-${message.type}`;

  const content = document.createElement('div');
  content.className = 'message-content';
  content.innerHTML = message.text.replace(/\n/g, '<br>');

  messageDiv.appendChild(content);
  chatMessages.appendChild(messageDiv);

  requestAnimationFrame(() => {
      messageDiv.classList.add('show');
      chatMessages.scrollTop = chatMessages.scrollHeight;
  });
}

function addTyping() {
  const typing = document.createElement('div');
  typing.className = 'typing';
  typing.innerHTML = `
      <div class="typing-dot"></div>
      <div class="typing-dot"></div>
      <div class="typing-dot"></div>
  `;
  chatMessages.appendChild(typing);
  chatMessages.scrollTop = chatMessages.scrollHeight;
  return typing;
}

async function playDemo() {
  for (const message of messages) {
      if (message.type === 'bot') {
          const typing = addTyping();
          await new Promise(resolve => setTimeout(resolve, 1500));
          typing.remove();
      }
      await new Promise(resolve => setTimeout(resolve, 300));
      addMessage(message);
      await new Promise(resolve => setTimeout(resolve, message.delay));
  }
}

setTimeout(playDemo, 1000);
